/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

// gatsby-browser.js
import $ from "jquery";

import "slick-carousel"; // This imports slick's functionality
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles

/** import "./layout.css" **/
import "./src/styles/reset.css"
import "./src/styles/style.css"
import "./src/styles/slick.css"

// Initialize Slick after components mount
export const onRouteUpdate = () => {

  $(document).ready(function() {
    $(".carousel").slick({
      slidesToShow: 1,
      loop: true,
      slideMove: 1,
      dots: true,
      prevArrow: $(".prev-arrow"),
      nextArrow: $(".next-arrow"),
      infinite: true,
	  autoplay: true,
    });


	var cards = document.querySelectorAll('.listing-item');
	Array.prototype.forEach.call(cards, card => {
		var
		down,
		up,
		link = card.querySelector('.listing-item-link');
		// check if inside listing section
	
		if (link) {
	
		card.style.cursor = 'pointer';
		card.addEventListener('mouseover', function(){
		card.classList.add('hovered');
		});
		card.addEventListener('mouseout', function(){
		card.classList.remove('hovered');
		});
		}
	
		card.addEventListener('mousedown', function(){
		down = new Date();
		if (Event.button == 2) {
				return;
		}
		});
		card.addEventListener('mouseup', function(e){
		if (e.button === 0) {
			up = new Date();
			if ((up - down) < 200 && e.target.tagName.toLowerCase() != 'a') {
			link.click();
			}
		}
		if (e.button == 2) {
				return;
		}
		});
	});
  });
};
